import React from 'react'
import * as styles from './Card.module.css'

export interface CardProps {
    children: React.ReactNode,
    className?: string,
}

export const Card: React.FC<CardProps> = (
  props,
  className
  ) => {
    return <div {...props} className={`${styles.card} ${className}`}>{ props.children }</div>
}



