import * as React from 'react'
import Layout from '../components/layout/Layout'
import { Card } from '../components/card/Card'
import { h1, hero, container, cards, image, info } from '../styles/home.module.css'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout>
      <div className={hero}>
        <div className={container}>
          <h1 className={h1}>I design, code and<br /> make fun stuff</h1>
          <div className={image}>
            <StaticImage aspectRatio={3/2} width={520} src="../images/david-leanne.jpg" alt="David Birchall" />
          </div>
        </div>
      </div>
      <div className={`${container} ${info}`}>
        <p>Hello and welcome, my name is <strong>David Birchall</strong> and I like to make things.</p>
        <p>I've been designing and building websites for around 10 years for many major brands around the world and thought it was time to create one for myself.</p>
        <p>I'm using this as a place to document some of the personal projects I'm working on and a place to share some of the things I discover along the way.</p>
      </div>
      <div className={container}>
        <h2>Areas of interest</h2>
        <p>Here are some of my main areas of interest that I will touch on...</p>
      </div>
      
      <div className={`${container} ${cards}`}>
        <Card> 
          <h2>Coding</h2>
          <p>Building websites in React or Vue.</p>
        </Card>
        <Card>
          <h2>Design</h2>
          <p>Solving design problems.</p>
        </Card>
        <Card> 
          <h2>Football</h2>
          <p>Or soccer in my new home (Canada).</p>
        </Card>
        <Card> 
          <h2>Games</h2>
          <p>Video, card and board games.</p>
        </Card>
        <Card> 
          <h2>Illustration</h2>
          <p>Learning to draw and digital painting.</p>
        </Card>
        <Card> 
          <h2>Lego</h2>
          <p>Building Lego sets.</p>
        </Card>
        <Card> 
          <h2>Making Stuff</h2>
          <p>Model making and DIY.</p>
        </Card>
        <Card> 
          <h2>Reading</h2>
          <p>Some of the things I've been reading.</p>
        </Card>
        <Card> 
          <h2>Travel</h2>
          <p>Some of the places I've been to.</p>
        </Card>
      </div>
    </Layout>
  )
}

export default IndexPage